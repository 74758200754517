<template>
  <base-modal :id="id">
    <div class="module">
      <h4>Delete Group from Team</h4>
    </div>
    <p>Are you sure you want to delete this group? All of these locations will move to the “Unassigned Team Locations” group.</p>
    <table class="no-zebra">
      <tbody>
        <tr>
          <td>
            <strong>{{ employeeName }}</strong>
          </td>
          <td>{{ locationsCount }} Locations</td>
          <td>{{ partnersCount }} Partners</td>
        </tr>
      </tbody>
    </table>
    <div class="button-group close-top">
      <a class="button warning" data-micromodal-close="data-micromodal-close" @click="deleteSpecialist()">Delete</a>
      <a class="button secondary" data-micromodal-close="data-micromodal-close">Cancel</a>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
export default {
  components: { BaseModal },
  props: {
    specialist: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      id: "modal-delete-group",
    };
  },
  computed: {
    employeeName() {
      return this.$props.specialist && this.$props.specialist.employee ? `${this.$props.specialist.employee.firstName} ${this.$props.specialist.employee.lastName}` : "";
    },
    locationsCount() {
      return this.$props.specialist ? this.$props.specialist.locations.length : 0;
    },
    partnersCount() {
      return this.$props.specialist
        ? this.$props.specialist.locations.reduce((total, value) => {
            return total + (value.partners_aggregate ? value.partners_aggregate.aggregate.count : 0);
          }, 0)
        : 0;
    },
  },
  methods: {
    deleteSpecialist() {
      this.$emit("onDeleteSpecialist", this.specialist);
    },
  },
};
</script>
