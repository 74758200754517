<template>
  <div class="assignments">
    <div class="assignment-header">
      <div class="inner">
        <div v-show="$props.specialist && !$props.specialist.employee" class="spread-md">
          <div class="field">
            <people-picker
              ref="onboardingSpecialists"
              auto-select
              person-label=""
              :search-results="availableOnboardingSpecialists"
              placeholder="Type onboarding specialist name"
              add-person-label="Add Onboarding Specialists"
              @peopleChanged="handleSpecialistSubmission"
            >
              <template #results><span v-show="false"></span></template>
            </people-picker>
          </div>
        </div>
        <p v-show="$props.specialist && $props.specialist.employee">
          <strong>{{ specialistName() }}</strong>
        </p>
        <ul class="data-column list-unstyled module-large">
          <li>
            <p>Locations: {{ locationCount() }}</p>
          </li>
          <li>
            <p>Total Partners: {{ partnerCount() }}</p>
          </li>
        </ul>
      </div>
      <div v-show="$props.specialist" class="control">
        <div class="more-menu">
          <i v-click-outside="hidePopup" class="icon-more-horizontal" @click="openPopup"></i>
          <nav v-show="popup === 'document-nav-0'" class="popup-nav long">
            <ul class="list-unstyled">
              <li><a @click="removeEmployeeFromGroup">Remove OS from Group</a></li>
              <li><a data-micromodal-trigger="modal-delete-group">Delete group from team</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="assignment-list">
      <div class="spread-md">
        <div class="field">
          <label>Search for a Location</label>
          <autocomplete ref="autocomplete" auto-select placeholder="Type Location Name" :search="search" :get-result-value="getResultValue" @submit="handleLocationSubmission" />
        </div>
      </div>
      <p>
        <a v-show="!!selectedLocation.id" @click="addLocation(selectedLocation)"><i class="icon-plus-circle"></i>{{ $props.assignLocationLabel }}</a>
      </p>
      <div class="table-container">
        <validation-provider :ref="$props.name" :name="$props.name" slim>
          <table :class="{ hidden: getLocationLength() === 0 }">
            <thead>
              <tr>
                <th>{{ $props.locationColumnHeader }}</th>
                <th>Partners</th>
                <th class="table-item-control"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(location, index) in getLocations()" :key="`${specialist ? 'spec' : ''}-${location.id}-${index}`">
                <td>{{ location.name }}</td>
                <td>{{ location.partners_aggregate.aggregate.count }}</td>
                <td>
                  <a @click="removeLocation(location)"><i :class="$props.deleteIcon"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </validation-provider>
      </div>
    </div>
  </div>
</template>
<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import ClickOutside from "vue-click-outside";
import micromodal from "micromodal";
import PeoplePicker from "@/components/pickers/PeoplePicker";
import { ValidationProvider } from "vee-validate";

export default {
  name: "LocationPicker",
  components: { Autocomplete, PeoplePicker, ValidationProvider },
  directives: { ClickOutside },
  props: {
    deleteIcon: {
      type: String,
      required: false,
      default: "icon-x-circle",
    },
    assignLocationLabel: {
      type: String,
      required: false,
      default: "Assign Location to Team Member",
    },
    locationColumnHeader: {
      type: String,
      required: false,
      default: "Assigned Locations",
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    searchResults: {
      type: Function,
      required: true,
      default: () => [],
    },
    availableOnboardingSpecialists: {
      type: Array,
      required: false,
      default: () => [],
    },
    specialist: {
      type: Object,
      required: false,
      default: null,
    },
    autoSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: "Location Picker",
    },
  },
  data() {
    return {
      locations: [],
      locationsLength: this.$props.specialist ? this.$props.specialist.locations.length : 0,
      selectedLocation: {},
      popup: false,
      initialized: false,
    };
  },
  watch: {
    selected(newValue) {
      if (newValue && !this.initialized) {
        newValue.forEach((location) => {
          if (location) {
            this.addLocation(location);
          }
        });
        this.locationsLength = this.locations.length;
        this.initialized = true;
      }
    },
  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {
    addLocation(location) {
      if (this.$props.specialist) {
        location.teamSpecialist = this.specialist;
        location.team = this.specialist.team;
        this.$props.specialist.locations.push(location);
      } else {
        this.locations.push(location);
      }
      const locationChangedEvent = {
        locations: this.$props.specialist ? this.$props.specialist.locations : this.locations,
        locationAdded: location,
        locationRemoved: null,
      };
      this.$emit("locationsChanged", locationChangedEvent);
      this.$refs["autocomplete"].value = "";
      this.selectedLocation = {};
    },
    removeLocation(location) {
      if (this.$props.specialist) {
        location.teamSpecialist = null;
        this.$props.specialist.locations = this.$props.specialist.locations.filter((existingLocation) => existingLocation.id !== location.id);
      } else {
        location.team = null;
        this.locations = this.locations.filter((existingLocation) => existingLocation.id !== location.id);
      }
      const locationChangedEvent = {
        locations: this.$props.specialist ? this.$props.specialist.locations : this.locations,
        locationAdded: null,
        locationRemoved: location,
      };
      this.$emit("locationsChanged", locationChangedEvent);
    },
    getLocations() {
      const length = this.$props.specialist ? this.$props.specialist.locations.length : this.locations.length;
      if (this.$refs[`${this.$props.name}`] && this.locationsLength !== length) {
        this.$refs[`${this.$props.name}`].setFlags({ dirty: true, pristine: false, touched: true, untouched: false, valid: true, invalid: false });
        this.locationsLength = length;
      }
      if (this.$props.specialist) {
        return this.$props.specialist.locations;
      }
      return this.locations;
    },
    getLocationLength() {
      if (this.$props.specialist) {
        return this.$props.specialist.locations.length;
      }
      return this.locations.length;
    },
    partnerCount() {
      if (this.$props.specialist) {
        return this.$props.specialist.locations
          ? this.$props.specialist.locations.reduce((total, value) => {
              return total + (value.partners_aggregate ? value.partners_aggregate.aggregate.count : 0);
            }, 0)
          : 0;
      }
      return this.locations.reduce((total, value) => {
        return total + (value.partners_aggregate ? value.partners_aggregate.aggregate.count : 0);
      }, 0);
    },
    locationCount() {
      if (this.$props.specialist) {
        return this.$props.specialist.locations ? this.specialist.locations.length : 0;
      }
      return this.locations.length;
    },
    search(input) {
      return this.searchResults().filter((location) => {
        const locationsToFilter = this.$props.specialist ? this.$props.specialist.locations : this.locations;
        const locationExists = locationsToFilter.filter((existingLocation) => existingLocation.id === location.id);
        return location.name.toLowerCase().includes(input.toLowerCase()) && locationExists.length === 0;
      });
    },
    handleLocationSubmission(location) {
      if (location && location.id) {
        this.selectedLocation = location;
        if (this.$props.autoSelect) {
          this.addLocation(location);
        }
      }
    },
    specialistName() {
      if (this.$props.specialist && this.$props.specialist.employee) {
        return `${this.$props.specialist.employee.firstName} ${this.$props.specialist.employee.lastName}`;
      }
      return "";
    },
    handleSpecialistSubmission(personChangedEvent) {
      if (personChangedEvent.personAdded) {
        this.$props.specialist.employee = personChangedEvent.personAdded;
        this.$emit("employeeAdded", personChangedEvent.personAdded);
      }
    },
    getResultValue(location) {
      return location.name;
    },
    removeEmployeeFromGroup() {
      if (this.$props.specialist.employee) {
        const employee = this.specialist.employee;
        this.$props.specialist.employee = null;
        this.$emit("employeeRemoved", employee);
      }
    },
    updateSpecialists(employees) {
      employees.forEach((employee) => this.$refs["onboardingSpecialists"].removePerson(employee));
    },
    openPopup() {
      this.popup = "document-nav-0";
      this.$emit("specialistPopup", this.$props.specialist);
    },
    hidePopup() {
      this.popup = false;
    },
  },
};
</script>
